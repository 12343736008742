<template>
  <section class="invoice-preview-wrapper">
    <div class="position-relative">
      <b-card class="invoice-preview-card">
        <div class="d-flex justify-content-between">
          <h2 class="">{{$t('arretes_list.title') }}</h2>
          <span
            v-b-tooltip.top.top.v-warning="
              !isOtAdmin &&
             $t('arretes_list.ot_admin') 
            "
          >
            <!-- :disabled="!isOtAdmin" -->
            <b-button
              v-if="isOtAdmin"
              class=""
              variant="primary"
              @click="
                $router.push({
                  name: 'arretes-add',
                  params: {},
                })
              "
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{ $t('bylaws_list.new_bylaws') }}</span>
            </b-button>
          </span>
        </div>

        <hr class="" />

        <!-- module de recherche -->
        <!-- <app-collapse>
          <app-collapse-item title="Filtrage et exportation">
          </app-collapse-item>
        </app-collapse> -->
        <!-- filtres -->
        <app-collapse>
          <app-collapse-item
            :title="$t('complaints.complaint_list.filters.title')"
          >
            <b-row>
              <b-col lg="6">
                <!-- recherche par reference -->
                <div class="d-flex flex-row-reverse nopadding">
                  <b-form-group
                    class="col pr-0 pl-0"
                    :label="$t('bylaws_list.filter_label_reference')"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="filterBy.reference"
                        type="text"
                        class="form-control"
                        :placeholder= "$t('arretes_list.filterBy.reference')"
                      />
                      <b-input-group-append
                        is-text
                        @click="
                          () => {
                            filterBy.reference = null
                            resetListWhenResetFilter()
                          }
                        "
                      >
                        <feather-icon
                          v-b-tooltip.hover.bottom="$t('arretes_list.clear_field')"
                          icon="XCircleIcon"
                          class="cursor-pointer"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
              </b-col>
              <b-col lg="6">
                <!-- recherche par numero -->
                <div class="d-flex flex-row-reverse nopadding">
                  <b-form-group
                    class="col pr-0 pl-0"
                    :label="$t('bylaws_list.filter_label_number')"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="filterBy.numero"
                        type="text"
                        class="form-control"
                        :placeholder="$t('arretes_list.filterBy_numero')"
                      />
                      <b-input-group-append
                        is-text
                        @click="
                          () => {
                            filterBy.numero = null
                            resetListWhenResetFilter()
                          }
                        "
                      >
                        <feather-icon
                          v-b-tooltip.hover.bottom="$t('arretes_list.clear_field')"
                          icon="XCircleIcon"
                          class="cursor-pointer"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
              </b-col>
              <b-col lg="6">
                <!-- recherche par validation du ministre -->
                <div class="d-flex flex-row-reverse nopadding">
                  <b-form-group
                    class="col pr-0 pl-0"
                    :label="$t('bylaws_list.filter_label_validation')"
                  >
                    <v-select
                      v-model="filterBy.validation_ministre"
                      :placeholder="''"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="label"
                      :reduce="(option) => option.value"
                      :options="validation_ministres"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col lg="6">
                <!-- recherche par statut -->
                <div class="d-flex flex-row-reverse nopadding">
                  <b-form-group
                    class="col pr-0 pl-0"
                    :label="$t('bylaws_list.filter_label_status')"
                  >
                    <v-select
                      v-model="filterBy.status"
                      :placeholder="''"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="label"
                      :reduce="(option) => option.value"
                      :options="statuses"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col lg="6">
                <!-- recherche par statut -->
                <div class="d-flex flex-row-reverse nopadding">
                  <b-form-group
                    class="col pr-0 pl-0"
                    :label="$t('bylaws_list.filter_label_created_by')"
                  >
                    <v-select
                      v-model="filterBy.created_by_id"
                      :placeholder="''"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="label"
                      :reduce="(option) => option.id"
                      :options="options_mtca_admins"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col lg="12">
                <!-- button -->
                <div class="d-flex flex-row-reverse nopadding">
                  <b-button
                    class="float-right"
                    variant="warning"
                    @click="getListArretes()"
                  >
                    <feather-icon icon="SearchIcon" class="mr-25" />
                    <span>{{ $t('button.search') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-card>

      <!-- list -->
      <b-card class="mt-1">
        <div class="my-1">
          <b-table
            ref="refFormsTable"
            :items="tableData"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Aucune donnée"
            :busy="isLoading"
            hover
          >
            <!-- Custom Header -->
            <template #head()="{ label, field: { key, sortable } }">
              {{ $t(label) }}
            </template>
            <!-- busy -->
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template #cell(statuses)="data">
              <!-- {{ data.item.status }} -->
              {{ statusMatching(data.item.process_steps, data.item.status) }}
            </template>
            <!-- created at -->
            <template #cell(created_at)="data">
              {{ data.item.created_at }}
            </template>
            <!-- validation_ministre -->
            <template #cell(validation_ministre)="data">
              <b-form-checkbox
                v-model="data.item.validation_ministre"
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
                @change="update_arrete(data.index, 'validation_ministre')"
              />
            </template>
            <!-- numero -->
            <template #cell(numero)="data">
              <b-form-input
                v-model="data.item.numero"
                type="text"
                :disabled="!can_set_ministerial_decree_number(data.item.status)"
                @change="update_arrete(data.index, 'numero')"
              />
            </template>
            <!-- demandes -->
            <template #cell(demandes)="data">
              {{ data.item.demandes.length }} demande(s)
            </template>
            <!-- actions -->
            <template #cell(actions)="data">
              <div class="d-flex justify-content-center">
                <span class="d-flex align-items-center">
                  <b-spinner v-if="isLoadingUpdate" small class="mr-1" />
                </span>
                <b-button
                  v-b-tooltip.hover.top="$t('arretes_list.view_details')"
                  variant="primary"
                  class="mx-0"
                  @click="
                    $router.push({
                      name: 'arretes-details',
                      params: {
                        id: data.item.id,
                        //slug: slug,
                      },
                    })
                  "
                >
                  <i class="las la-eye" />
                </b-button>
              </div>
            </template>
          </b-table>
          <!-- pagination -->
          <div>
            <PaginationComponent
              :table-data="tableData"
              :per-page="paginationData.perPage"
              :total-rows="paginationData.total"
              @change="onPaginationChange"
            />
          </div>
        </div>
      </b-card>

      <!-- modal for notification -->
      <!-- <b-modal
        id="modal-option-demandes"
        ref="modal-option-demandes"
        hide-backdrop
        ok-only
        no-close-on-backdrop
        content-class="shadow"
        title="Quelle opération souhaitez-vous effectuer ?"
        ok-title="Continuer"
        @ok="fillForm()"
      >
        <b-form-group label="">
          <b-form-radio-group v-model="selectedDemandes">
            <b-form-radio
              v-for="(demande, index) in optionsDemandes"
              :key="index"
              name="demande"
              :value="demande"
              class="w-100"
            >
              {{ demande.title }}
              <i
                v-b-tooltip.hover.top.v-primary="demande.description"
                class="las la-info-circle ml-1"
              />
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-modal> -->
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
  BModal,
  BFormRadioGroup,
  BFormGroup,
  BFormRadio,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { mapActions } from 'vuex'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import utilsService from '@/services/utils/utils.service'

import formulairesStoreModule from '@/store/formulaire'
import etablissementsStroreModule from '@/store/etablissements'
import usersStoreModule from '@/store/users'
import arretesStoreModule from '@/store/arretes'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import { buildFiltersForRequest } from '@/helpers/http-utils'
import Bails_states from './bails_states.js'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,
    flatPickr,
    BFormRadioGroup,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText,
    BFormRadio,

    AppCollapse,
    AppCollapseItem,

    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    TipsModalCreateFromLastRecord,
    DateRangeWidget,
    PaginationComponent,
    ToastificationContent,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'formulaires', module: formulairesStoreModule },
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'users', module: usersStoreModule },
      { path: 'arretes', module: arretesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    const { isMtcaAdmin, isOtAdmin } = utilsService.currentUserUtils()

    const departementsList = utilsService.getDepartements()
    const communesList = utilsService.getCommunes()

    return {
      departementsList,
      communesList,
      isMtcaAdmin,
      isOtAdmin,
      requiredStoreModules,
    }
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      etablissementsList: [],
      isLoadingEtablissementsList: true,
      series: [
        {
          name: 'Fiches',
          data: [95, 177, 84],
        },
      ],
      loadingExport: false,
      isLoading_update_state: false,

      loading: false,
      statsLoad: null,

      lastestRecordedForm: null,

      filterBy: {
        //  periode: null,
        reference: null,
        numero: null,
        created_by_id: null,
        validation_ministre: null,
        status: null,
      },

      periode: null,
      reference: null,
      firstname: null,
      lastname: null,

      curPage: 1,
      perPage: 20,
      query: {
        options: {},
        filter: {},
      },
      tableColumns: [
        {
          key: 'created_at',
          label: 'table_columns.created_at',
          sortable: true,
        },
        {
          key: 'reference',
          label: 'table_columns.reference',
          sortable: true,
        },
        {
          key: 'numero',
          label: 'table_columns.numero',
          sortable: true,
        },
        {
          key: 'validation_ministre',
          label: 'table_columns.validation_minister',
          sortable: true,
        },
        {
          key: 'statuses',
          label: 'table_columns.status',
          sortable: true,
        },
        {
          key: 'demandes',
          label: 'table_columns.requests',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'table_columns.actions',
          sortable: true,
          thStyle: { width: '5%' },
        },
      ],

      tableData: [],
      tableDataCommissariats: [],

      filteredTableData: [],
      form_title: this.$route.params.title || '',
      selectedForm: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 15,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      isLoading: false,
      isLoadingUpdate: false,
      draggingOver: false,
      model: '',
      useCamera: false,
      loadQR: false,
      statuses: [
        {
          label: 'En cours de traitement',
          value: 'pending',
        },
        {
          label: 'Rejeté',
          value: 'rejected',
        },
        {
          label: 'Accepté',
          value: 'accepted',
        },
      ],
      validation_ministres: [
        {
          label: 'Rejeté',
          value: false,
        },
        {
          label: 'Accepté',
          value: true,
        },
      ],
      optionsStats: [
        { id: 1, label: '3 derniers trimestres', value: '3t' },
        { id: 2, label: '3 derniers semestres', value: '3s' },
        { id: 3, label: '3 dernières années', value: '3a' },
      ],
      selectedStats: { id: 3, label: '3 dernières années', value: '3a' },
      // slug: null,
      isAgentPolice: null,
      isCommissaire: null,
      isSuperAdmin: null,

      months: utilsService.getListMonths(),
      years: utilsService.getListYears(),

      optionsDemandes: null,
      selectedDemandes: null,
      options_mtca_admins: [],
      states: null,
    }
  },

  computed: {},

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        this.states = Bails_states
        this.init()
        this.fetchUsers()
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.getListArretes()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.getListArretes()
      },
    },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {},

  methods: {
    ...mapActions('formulaires', {
      action_getLastFormulaireCreatedByUser: 'getLastFormulaireCreatedByUser',
      action_searchFormulaires: 'searchFormulaires',
      action_fetchFormulaires: 'fetchFormulaires',
      action_addFormulaire: 'addFormulaire',
      action_updateFormulaire: 'updateFormulaire',
    }),
    ...mapActions('etablissements', {
      action_findEtablissement: 'findEtablissement',
    }),
    ...mapActions('etablissements', {
      action_findEtablissement: 'findEtablissement',
    }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    ...mapActions('users', {
      action_searchUsers: 'searchUsers',
    }),
    ...mapActions('arretes', {
      action_fetchArretes: 'fetchArretes',
      actions_searchArretes: 'searchArretes',
      actions_updateArrete: 'updateArrete',
    }),
    // -------------------------------------------------------------------------------
    init() {
      // const { listFormulairesBySector } = utilsService.currentUserUtils();
      // this.optionsDemandes = listFormulairesBySector;
      this.getListArretes()
    },
    getListArretes() {
      this.isLoading = true
      // const { userData } = utilsService.currentUserUtils();
      const params = {
        ...this.filterBy,
        // identity: userData.identity,
        page: this.paginationData.currentPage,
        perPage: this.paginationData.perPage,
      }
      // console.log("params::: ", params);
      this.actions_searchArretes(params)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false
            this.tableData = []
            res.data.data.forEach((item) => {
              this.tableData.push({
                ...item,
                process_steps: this.states,
              })
            })
            console.log('this.tableData::: 🟠', this.tableData)
            // --------------------------------------------------------------
            this.paginationData.total = res.data.meta.total || 0
            if (this.paginationData.metaData.from === 0) {
              this.paginationData.metaData.from += 1
            } else {
              this.paginationData.metaData.from =
                this.paginationData.perPage * this.paginationData.currentPage -
                this.paginationData.perPage
            }
            this.paginationData.metaData.to =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage +
              res.data.meta.total
            // --------------------------------------------------------------
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoading = false
        })
    },
    fetchUsers() {
      const params_mtca_admins = {
        role: 'UAT',
      }
      this.action_searchUsers(params_mtca_admins)
        .then(async (response) => {
          if (response.status === 200) {
            this.options_mtca_admins = response.data.data
            this.options_mtca_admins.forEach((auditeur) => {
              auditeur.label = `${auditeur.lastname} ${auditeur.firstname}, ${auditeur.email}`
            })
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
        })
    },
    update_arrete(index, attribut) {
      const request = this.tableData[index]
      const params = {}
      params[attribut] = request[attribut]
      this.isLoadingUpdate = true
      // return;
      this.actions_updateArrete({
        id: request.id,
        data: params,
      })
        .then((res) => {
          this.isLoadingUpdate = false
          if (res.status === 202) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: $t('arretes_list.update_arrete'),
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingUpdate = false
        })
    },
    update_status(id, state) {
      this.isLoading_update_state = true
      this.action_updateFormulaire({
        id,
        data: { status: state },
      })
        .then((result) => {
          if (result.status === 200) {
            this.isLoading_update_state = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: $t('arretes_list.update'),
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            this.init()
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
          this.isLoading_update_state = false
        })
    },
    fillForm() {
      if (!this.selectedDemandes) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: $t('arretes_list.selectedDemandes'),
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
      window.open(this.selectedDemandes.demande_link)
    },
    resetListWhenResetFilter() {
      this.filterBy = JSON.parse(
        JSON.stringfy({
          // periode: null,
          reference: null,
          numero: null,
          created_by_id: null,
          validation_ministre: null,
          status: null,
        })
      )
      this.getListArretes()
    },
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.perPage = val.perPage
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '@core/scss/base/pages/app-invoice.scss';

.per-page-selector {
  width: 90px;
}

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;

  .options-sidebar {
    background: #fff;
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}

.v-select {
  width: 100%;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;

    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}

.d-content {
  display: contents;
}

#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;

  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
